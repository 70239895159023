import React, { useState } from "react"

const CheckBox = ({ onChange, id, filter, value }) => {
  const [checked, setChecked] = useState(true)

  const handleChange = event => {
    setChecked(!checked)
    onChange(
      event.target.dataset.filter,
      event.target.value,
      event.target.checked
    )
  }

  return (
    <input
      id={id}
      data-filter={filter}
      type="checkbox"
      className="mr-1 form-checkbox text-accent-500 ml-1 align-middle -mt-1"
      checked={!checked}
      onChange={handleChange}
      value={value}
    />
  )
}

export default CheckBox
