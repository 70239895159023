import React from "react"
import { default as SanityContent } from "@sanity/block-content-to-react"
import serializers from "utils/serializers"
import PropTypes from "prop-types"

const BlockContent = ({ blocks, className }) => {
  return (
    <>
      <div className={className}>
        <SanityContent blocks={blocks} serializers={serializers} />
      </div>
    </>
  )
}

BlockContent.propTypes = {
  blocks: PropTypes.array,
  className: PropTypes.string,
}

export default BlockContent
