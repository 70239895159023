import React from "react"
import PropTypes from "prop-types"
import { Link } from "components"

const Button = ({ isPrimary, children, to, className, type, ...props }) => {
  return (
    <button
      {...props}
      type={type}
      className={`btn ${isPrimary ? "is-primary" : ""} ${
        className && className
      }`}
    >
      {to ? <Link to={to}>{children}</Link> : children}
    </button>
  )
}

Button.propTypes = {
  isPrimary: PropTypes.bool,
}

export default Button

// const Button = styled.button(({ isPrimary }) => [
//   tw``,
//   // Hover + Focus
//   tw`outline-none!`,
//   // Hover
//   tw`hover:-translate-y-1`,

//   css`
//     background-size: 400% 100%;
//     text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
//     &:hover {
//       background-position: 100% 0;
//     }
//   `,

//   isPrimary
//     ? tw`bg-gradient-r-green shadow-green`
//     : tw`bg-gradient-r-blue shadow-blue`,
// ])

// export default Button
