import React from "react"
import {
  Logo,
  Link,
  Heading,
  BadgeISO,
  BadgeAward,
  SocialMediaIcons,
  NeatGrowth,
} from "components"
import { useStaticQuery, graphql } from "gatsby"
import { FaMapMarkerAlt, FaHeadset } from "react-icons/fa"

const Footer = () => {
  const {
    settings,
    departments,
    solutions,
    markets,
    products,
  } = useStaticQuery(graphql`
    {
      settings: sanitySettings {
        description
        pages {
          title
          slug {
            current
          }
        }
      }
      departments: allSanityDepartment {
        edges {
          node {
            title
            slug {
              current
            }
          }
        }
      }
      solutions: allSanitySolution {
        edges {
          node {
            title
            slug {
              current
            }
          }
        }
      }
      markets: allSanityMarket {
        edges {
          node {
            title
            slug {
              current
            }
            _rawParent
          }
        }
      }
      products: allSanityProduct(sort: { fields: title, order: ASC }) {
        edges {
          node {
            title
            slug {
              current
            }
          }
        }
      }
    }
  `)

  return (
    <footer className="bg-primary-800">
      <div className="container py-16">
        <div className="grid lg:grid-cols-12 gap-16">
          <div className="lg:col-span-3">
            <Link className="text-left justify-start flex w-48 mb-8" to="/">
              <Logo wordColor="text-white" />
            </Link>

            <SocialMediaIcons />

            <div className="text-white text-sm mt-8">
              <p>{settings.description}</p>
            </div>

            <div className="grid grid-cols-5 items-center gap-8 py-8">
              <BadgeAward className="text-white col-span-3" />
              <BadgeISO className="text-white  col-span-2" />
            </div>
          </div>

          <div className="lg:col-span-2"></div>

          <Column
            className="col-span-2 lg:col-span-2"
            title="Navegação"
            links={[
              ...settings.pages,
              {
                title: "Política de Privacidade",
                slug: { current: "politica-de-privacidade" },
              },
            ]}
          />

          <Column
            className="lg:col-span-2"
            title="Departamentos"
            links={departments.edges}
          />

          <div className="lg:col-span-3 text-white space-y-3">
            <Heading level="6" className="text-accent-500">
              Onde estamos
            </Heading>
            <p className="flex items-center space-x-3">
              <FaMapMarkerAlt className="text-accent-500" />
              <strong>RS</strong>
            </p>
            <p>
              Rua Diretor Augusto Pestana 2250
              <br /> Canoas - RS/Brasil
            </p>
            <p className="flex items-start space-x-3 space-y-0 items-center">
              <FaHeadset className="text-accent-500" />
              <span>(51) 34258666</span>
            </p>
            <p className="flex items-center space-x-3">
              <FaMapMarkerAlt className="text-accent-500" />
              <strong>SP</strong>
            </p>
            <p>
              Rua Alberto Guizzo 151 <br />
              Indaiatuba - SP/Brasil
            </p>
            <p className="flex items-center space-x-3 items-center">
              <FaHeadset className="text-accent-500" />
              <span>(19) 39366979</span>
            </p>{" "}
          </div>

          <hr className="col-span-2 lg:col-span-12 border-primary-500" />

          <Column
            className="lg:col-span-6"
            grid="3"
            title="Soluções"
            links={solutions.edges}
            linkAscender="solucoes/"
          />
          <Column
            className="lg:col-span-6"
            grid="3"
            title="Segmentos"
            linkAscender="produtos-para-"
            links={markets.edges.filter(
              market => market.node._rawParent === null
            )}
          />

          <hr className="col-span-2 lg:col-span-12 border-primary-500" />

          <Column
            grid="6"
            className="col-span-2 lg:col-span-12"
            title="Todos os Produtos"
            links={products.edges}
            linkAscender="produtos/"
          />
        </div>
      </div>
      <div className="bg-primary-900 py-4">
        <div className="container text-primary-500 flex flex-wrap justify-between text-center md:text-left space-y-4 md:space-y-0">
          <div className="w-full md:w-auto ">
            ©{new Date().getFullYear()},{" "}
            <span className="text-primary-300">Bondmann Química LDTA</span>.
            Todos os direitos reservados.
          </div>

          <div className="w-full md:w-auto text-white text-xs uppercase flex space-x-4 items-center justify-center md:justify-start">
            <div className="text-primary-300">website por</div>
            <Link to="https://neatgrowth.com/?utm_source=client&utm_medium=website&utm_campaign=footer_logo&utm_term=bondmann">
              <NeatGrowth className="w-24 text-white" />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export const Column = ({ className, linkAscender, links, title, grid }) => {
  return (
    <div className={className}>
      <Heading level="6" className="text-accent-500">
        {title}
      </Heading>
      {links && (
        <ul
          className={`list-none text-white text-sm w-full ${
            grid === "6" ? `md:grid-list-2 lg:grid-list-6` : ""
          }

          ${grid === "3" ? `md:grid-list-2 lg:grid-list-3` : ""}`}
        >
          {links.map(link => {
            const { title, slug } = link.node ? link.node : link
            return (
              <li key={slug.current}>
                <Link
                  className="transition hover:underline"
                  to={`${linkAscender ? `/${linkAscender}` : "/"}${
                    slug.current
                  }`}
                >
                  {title}
                </Link>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default Footer
