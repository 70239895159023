import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const Navigation = () => {
  const { settings } = useStaticQuery(query)

  return (
    <div className="border-t border-gray-300">
      <div className="container">
        <nav className="flex items-center border-l border-r border-gray-300 divide-x divide-gray-300 justify-">
          {settings.pages.map(page => (
            <Link
              key={page.slug.current}
              className="nav-item"
              partiallyActive={"/" !== page.slug.current && true}
              to={
                "/" === page.slug.current
                  ? page.slug.current
                  : "servicos" === page.slug.current
                  ? `/bd-service`
                  : `/${page.slug.current}`
              }
              activeClassName="is-active"
            >
              <span>{page.title}</span>
            </Link>
          ))}
          <Link
            className="hidden lg:block nav-item"
            target="_blank"
            to={"https://bondmann.vagas.solides.com.br/"}
            activeClassName="is-active"
          >
            <span>Trabalhe Conosco</span>
          </Link>
        </nav>
      </div>
    </div>
  )
}

const query = graphql`
  {
    settings: sanitySettings {
      title
      pages {
        title
        slug {
          current
        }
      }
    }
  }
`

export default Navigation
