import PropTypes from "prop-types"
import React from "react"

const Heading = ({ level = 2, children, className }) => {
  const Hx = level > 6 ? "h6" : `h${level}`
  return <Hx className={`italic ${className ? className : ""}`}>{children}</Hx>
}

Heading.propTypes = {
  level: PropTypes.oneOf(["1", "2", "3", "4", "5", "6"]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  // type: PropTypes.oneOf(["headline", "subtitle", "title"]),
  className: PropTypes.string,
}
export default Heading
