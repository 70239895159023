// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-busca-js": () => import("./../../../src/pages/busca.js" /* webpackChunkName: "component---src-pages-busca-js" */),
  "component---src-pages-default-js": () => import("./../../../src/pages/default.js" /* webpackChunkName: "component---src-pages-default-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/CategoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-department-template-js": () => import("./../../../src/templates/DepartmentTemplate.js" /* webpackChunkName: "component---src-templates-department-template-js" */),
  "component---src-templates-lead-gen-template-js": () => import("./../../../src/templates/LeadGenTemplate.js" /* webpackChunkName: "component---src-templates-lead-gen-template-js" */),
  "component---src-templates-market-template-js": () => import("./../../../src/templates/MarketTemplate.js" /* webpackChunkName: "component---src-templates-market-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/ProductTemplate.js" /* webpackChunkName: "component---src-templates-product-template-js" */),
  "component---src-templates-solution-template-js": () => import("./../../../src/templates/SolutionTemplate.js" /* webpackChunkName: "component---src-templates-solution-template-js" */)
}

