import React from "react"
import { Section, Form } from "components"
import { useStaticQuery, graphql } from "gatsby"

const CallToAction = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "bondmann-verde.png" }) {
        asset: childImageSharp {
          fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Section bgImage={data.file}>
      <div className="max-w-xl">
        <Form lgpd name="contato-comercial" />
      </div>
    </Section>
  )
}

export default CallToAction
