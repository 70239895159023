import React, { useState } from "react"

const Input = ({
  name,
  placeholder,
  type = "text",
  tag,
  lgpd,
  value,
  onChange,
  required,
}) => {
  const [state, setState] = useState({})
  const Tag = tag ? tag : "input"
  return (
    <label
      htmlFor={name}
      className={
        lgpd && "text-left items-top flex justify-start space-y-0 my-4"
      }
    >
      {!lgpd && <span className={`hidden`}>{placeholder}</span>}

      <input
        onChange={onChange}
        id={name}
        name={name}
        required={required}
        type={type}
        className={`outline-none px-4 py-2 border-gray-300 border rounded-sm flex items-center placeholder-gray-500 focus:text-primary-500 focus:border-primary-500 ${
          lgpd ? "mt-2" : "mb-2 w-full"
        }`}
        placeholder={placeholder}
        value={value}
      />

      {lgpd && <span className="mb-2 block ml-2">{placeholder}</span>}
    </label>
  )
}

export default Input
