import React from "react"
import { motion } from "framer-motion"

const Path = ({ className, ...props }) => (
  <motion.path
    className={`fill-current ${className && className}`}
    {...props}
  />
)

// const white = `text-white`

const Logo = ({
  wordColor = `text-primary-500`,
  symbolColor = `text-accent-500`,
}) => {
  return (
    <svg
      viewBox="0 0 489 100"
      xmlns="http://www.w3.org/2000/svg"
      className="w-full fill-current"
    >
      {/* B */}
      <Path
        d="M35.5642 49.7684C36.932 49.3052 40.5797 46.5262 40.5797 39.5786C40.5797 34.0206 36.932 27.5362 23.2534 27.5362H2.27976C0.455952 27.5362 0 28.4625 0 29.852V70.1479C0 71.5374 0.455952 72.4637 2.27976 72.4637H23.2534C36.932 72.4637 40.5797 66.4425 40.5797 60.4213C40.5797 53.0106 36.932 50.2315 35.5642 49.7684ZM23.2534 60.8845H11.8546V38.1891H23.2534C27.357 38.1891 29.6369 38.1891 29.6369 41.8945C29.6369 44.6735 27.813 45.1367 23.2534 45.1367H19.6059C17.7821 45.1367 17.3261 46.063 17.3261 47.4525V52.5474C17.3261 53.9369 17.7821 54.8632 19.6059 54.8632H23.2534C27.813 54.8632 29.6369 55.3264 29.6369 58.1054C29.6369 60.8845 26.901 60.8845 23.2534 60.8845Z"
        className={wordColor}
      />

      {/* O */}
      <Path
        d="M88.4059 55.3264C88.4059 67.3688 76.4658 72.4637 67.3914 72.4637C57.8393 72.4637 46.3769 67.3688 46.3769 55.3264V44.6735C46.3769 32.6311 57.8393 27.5362 67.3914 27.5362C76.9434 27.5362 88.4059 32.6311 88.4059 44.6735V55.3264ZM75.9883 45.5998C75.9883 40.505 72.1674 37.726 67.3914 37.726C62.1377 37.726 58.7946 40.505 58.7946 45.5998V54.4001C58.7946 59.4949 62.6153 62.2739 67.3914 62.2739C72.645 62.2739 75.9883 59.4949 75.9883 54.4001V45.5998Z"
        className={wordColor}
      />

      {/* N */}
      <Path
        d="M131.621 27.5362H126.548C125.165 27.5362 124.243 27.9946 124.243 29.8284V53.2091L108.103 30.2869C107.181 28.9116 106.719 27.5362 104.875 27.5362H97.958C96.1135 27.5362 95.6523 28.4531 95.6523 29.8284V69.7131C95.6523 71.5468 96.5746 72.0053 97.958 72.0053H103.03C105.336 71.5468 107.181 69.7131 107.642 67.4208V46.3324L123.781 69.7131C124.704 71.0884 125.165 72.4637 127.009 72.4637H133.926C135.771 72.4637 136.232 71.5468 136.232 70.1715V33.0375C135.771 29.8284 133.926 27.9946 131.621 27.5362Z"
        className={wordColor}
      />

      {/* D */}
      <Path
        d="M182.609 45.1367V55.3264C182.609 67.3688 171.233 72.4637 162.588 72.4637H145.753C143.933 72.4637 143.478 71.5374 143.478 70.1479V29.852C143.478 28.4625 143.933 27.5362 145.753 27.5362H162.588C171.688 27.9994 182.609 33.0942 182.609 45.1367ZM170.778 46.063C170.778 41.4313 167.138 38.6523 162.588 38.6523H155.308V61.8108H162.588C167.593 61.8108 170.778 59.0318 170.778 54.4001V46.063Z"
        className={wordColor}
      />
      {/* M */}
      <Path
        d="M237.681 32.1679C237.217 29.852 235.36 27.9994 233.038 27.5362H228.859C226.537 27.5362 225.144 29.3889 224.68 30.3152L214 46.063L203.321 30.3152C202.856 29.3889 201.928 27.5362 199.142 27.5362H192.177C190.319 27.5362 189.855 28.4625 189.855 29.852V70.1479C189.855 72.0005 190.784 72.4637 192.177 72.4637H197.284C199.606 72.0005 201.463 70.1479 201.928 67.832V46.9894L209.821 60.4213C210.286 60.8845 210.75 61.8108 212.143 61.8108H215.858C216.786 61.8108 217.715 60.8845 218.179 60.4213L225.609 46.9894V70.1479C225.609 72.0005 226.537 72.4637 227.93 72.4637H232.574C234.895 72.0005 236.753 70.1479 237.217 67.832V67.3688L237.681 32.1679Z"
        className={wordColor}
      />

      {/* A */}
      <Path
        d="M288.125 70.1479L271.739 31.7047V31.2416C270.803 29.3889 268.93 27.9994 266.589 27.5362C266.121 27.5362 266.121 27.5362 265.653 27.5362H260.971C259.098 27.5362 258.63 28.4625 257.694 29.852L245.053 59.9581L241.776 67.832L240.84 70.1479C240.371 71.0742 240.371 72.4637 242.244 72.4637H247.862C248.33 72.4637 248.33 72.4637 248.798 72.4637C251.139 72.0005 253.012 70.611 253.948 68.2952V67.832L255.353 64.1266H274.08L276.421 70.1479C276.889 72.0005 277.826 72.4637 279.23 72.4637H287.189C288.594 72.4637 288.594 71.0742 288.125 70.1479ZM258.63 56.2528L263.78 40.505L264.248 39.1154L269.867 55.7896H258.63V56.2528Z"
        className={wordColor}
      />

      {/* N */}
      <Path
        d="M328.557 27.5362H323.304C321.871 27.5362 320.916 27.9946 320.916 29.8284V53.2091L304.2 30.2869C303.244 28.9116 302.767 27.5362 300.856 27.5362H293.692C291.782 27.5362 291.304 28.4531 291.304 29.8284V69.7131C291.304 71.5468 292.26 72.0053 293.692 72.0053H298.946C301.334 71.5468 303.244 69.7131 303.722 67.4208V46.3324L320.438 69.7131C321.393 71.0884 321.871 72.4637 323.781 72.4637H330.945C332.856 72.4637 333.333 71.5468 333.333 70.1715V33.0375C333.333 29.8284 331.423 27.9946 328.557 27.5362Z"
        className={wordColor}
      />

      {/* N */}
      <Path
        d="M376.548 27.5362H371.475C370.092 27.5362 369.17 27.9946 369.17 29.8284V53.2091L353.03 30.2869C352.108 28.9116 351.647 27.5362 349.802 27.5362H342.885C341.041 27.5362 340.579 28.4531 340.579 29.8284V69.7131C340.579 71.5468 341.502 72.0053 342.885 72.0053H347.958C350.263 71.5468 352.108 69.7131 352.569 67.4208V46.3324L368.709 69.7131C369.631 71.0884 370.092 72.4637 371.936 72.4637H378.853C380.698 72.4637 381.159 71.5468 381.159 70.1715V33.0375C381.159 29.8284 378.853 27.9946 376.548 27.5362Z"
        className={wordColor}
      />

      <Path
        d="M444.203 0C452.707 0 459.42 7.03322 459.42 15.942C459.42 24.8508 452.707 31.8841 444.203 31.8841C435.699 31.8841 428.986 24.8508 428.986 15.942C428.986 7.50211 435.699 0 444.203 0ZM444.203 23.4442C448.231 23.4442 451.364 20.162 451.364 15.942C451.364 11.7221 448.231 8.43988 444.203 8.43988C440.175 8.43988 437.042 11.7221 437.042 15.942C437.042 20.162 440.175 23.4442 444.203 23.4442Z"
        className={symbolColor}
      />
      <Path
        d="M444.203 68.1159C452.707 68.1159 459.42 75.1492 459.42 84.058C459.42 92.9668 452.707 100 444.203 100C435.699 100 428.986 92.9668 428.986 84.058C428.986 75.1492 435.699 68.1159 444.203 68.1159ZM444.203 91.5601C448.231 91.5601 451.364 88.2779 451.364 84.058C451.364 79.838 448.231 76.5558 444.203 76.5558C440.175 76.5558 437.042 79.838 437.042 84.058C437.042 88.2779 440.175 91.5601 444.203 91.5601Z"
        className={symbolColor}
      />
      <Path
        d="M402.022 74.6114C397.927 67.035 400.202 57.0909 407.482 52.8292C414.762 48.5674 424.317 50.9351 428.413 58.5115C432.508 66.0879 430.232 76.0319 422.952 80.2937C415.217 85.0289 406.117 82.1878 402.022 74.6114ZM421.587 62.7732C419.767 58.985 415.217 58.038 411.577 59.9321C407.937 61.8262 407.027 66.5614 408.847 70.3496C410.667 74.1378 415.217 75.0849 418.857 73.1908C422.497 71.2967 423.407 66.5614 421.587 62.7732Z"
        className={symbolColor}
      />
      <Path
        d="M459.993 40.1153C455.898 32.9076 458.173 23.4477 465.453 19.3934C472.733 15.3391 482.289 17.5915 486.384 24.7991C490.479 32.0067 488.204 41.4667 480.923 45.5209C473.643 50.0257 464.088 47.7733 459.993 40.1153ZM479.558 28.8534C477.738 25.2496 473.188 24.3486 469.548 26.1505C465.908 27.9524 464.998 32.4572 466.818 36.061C468.638 39.6648 473.188 40.5657 476.828 38.7638C480.468 36.9619 481.834 32.4572 479.558 28.8534Z"
        className={symbolColor}
      />
      <Path
        d="M486.397 74.6638C482.329 82.1905 472.837 85.013 465.605 80.3088C458.373 76.0751 455.661 66.1962 460.181 58.6695C464.249 51.1428 473.741 48.3202 480.973 53.0244C488.205 57.7287 490.465 67.1371 486.397 74.6638ZM466.961 62.9033C465.153 66.6667 466.057 71.3709 469.673 73.2525C473.289 75.1342 477.809 74.1934 479.617 70.43C481.425 66.6667 480.521 61.9624 476.905 60.0808C473.289 58.1991 468.769 59.6104 466.961 62.9033Z"
        className={symbolColor}
      />
      <Path
        d="M428.33 40.2423C424.069 47.4269 414.125 50.1211 406.548 45.6308C398.972 41.5894 396.131 32.1596 400.866 24.975C405.127 17.7904 415.072 15.0962 422.648 19.5866C430.224 24.077 432.592 33.0577 428.33 40.2423ZM407.969 29.0164C406.075 32.6087 407.022 37.099 410.81 38.8952C414.598 40.6913 419.333 39.7933 421.227 36.201C423.122 32.6087 422.174 28.1183 418.386 26.3221C414.598 24.526 409.863 25.8731 407.969 29.0164Z"
        className={symbolColor}
      />
    </svg>
  )
}

export default Logo
