import React from "react"
import PropTypes from "prop-types"
import toPlainText from "utils/toPlainText"
import kebabCase from "lodash.kebabcase"
import deburr from "lodash.deburr"

const Features = ({ data, title, color }) => {
  const features = data ? toPlainText(data).split(/\n+/) : []

  return (
    <div className="overflow-hidden rounded-sm shadow-soft">
      <table className="table-auto border-b-4 border-accent-500 w-full">
        <thead>
          <tr>
            <th
              colSpan="2"
              className="rounded-t-sm px-4 py-2 bg-primary-500 text-white"
            >
              Características do Produto
            </th>
          </tr>
        </thead>
        <tbody>
          {color && (
            <tr>
              <td className="px-4 py-2 font-bold last:rounded-full ">
                Coloração
              </td>
              <td className="px-4 py-2">
                {color !== "transparent" ? (
                  <div
                    className="px-6 rounded-sm inline-block"
                    style={{ background: color, color: color }}
                  >
                    {color}
                  </div>
                ) : (
                  "Incolor"
                )}
              </td>
            </tr>
          )}
          {features.map((feature, index) => {
            const line = feature.split(":")
            const key = kebabCase(deburr(line[0]))
            return (
              <tr key={key} className={`${index % 2 ? "" : "bg-gray-100"}`}>
                <td className="px-4 py-2 font-bold last:rounded-full">
                  {line[0]}
                </td>
                <td className="px-4 py-2">{line[1]}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

Features.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
}

export default Features
