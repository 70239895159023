import React from "react"

const BadgeISO = ({ className }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Bondmann - ISO 9001"
        viewBox="0 0 65.48 57.64"
        className={`fill-current ${className ? className : ""}`}
      >
        <path d="M60.13,0H5.35A5.37,5.37,0,0,0,0,5.35V52.3a5.35,5.35,0,0,0,5.35,5.34H60.13a5.35,5.35,0,0,0,5.35-5.34V5.35A5.37,5.37,0,0,0,60.13,0ZM21.37,42a10.62,10.62,0,0,1-1.17,2.24L18,47.68a.9.9,0,0,1-.86.51H14.85c-.32,0-.41-.33-.23-.56L17,44.27a3.39,3.39,0,0,1-.64.07,3.85,3.85,0,0,1-3.88-3.79A4.48,4.48,0,0,1,17,36.17c2.21,0,4.61,1.55,4.61,4.13A4.93,4.93,0,0,1,21.37,42ZM18.46,23.74c-.42,0-.52-.19-.52-.51V21.78c0-.33.1-.52.52-.52h5c.41,0,.52.19.52.52v1.45c0,.32-.11.51-.52.51H22.35v6.84h1.13c.41,0,.52.19.52.52v1.41c0,.32-.11.51-.52.51H18.42c-.41,0-.52-.19-.52-.51V31.1c0-.33.11-.52.52-.52h1.13V23.74ZM32.2,44.37c0,2.83-2.49,4.06-4.47,4.06s-4.45-1.23-4.45-4.06V40.21c0-2.83,2.48-4.06,4.45-4.06s4.47,1.23,4.47,4.06ZM29.1,28.11c-1.81-.58-3.47-1.29-3.47-3.56S27.74,21.08,30,21s4.75,1,4.75,3.4c0,.43-.1.5-.52.5H32.46c-.34,0-.39-.25-.48-.48a1.36,1.36,0,0,0-.38-.54,2.38,2.38,0,0,0-1.52-.48c-.59,0-1.57.23-1.57,1,0,1.1,2.63,1.49,3.34,1.78,1.29.39,3.19.9,3.19,3.34s-2,3.75-4.76,3.75c-2.24,0-4.81-1.29-4.81-3.79,0-.41.11-.5.52-.5h1.73c.41,0,.61.06.65.5a1.16,1.16,0,0,0,.34.74,2.34,2.34,0,0,0,1.75.59c1,0,1.75-.22,1.75-1.22S30.62,28.59,29.1,28.11ZM42.9,44.39c0,2.83-2.49,4.06-4.47,4.06S34,47.22,34,44.39V40.21c0-2.83,2.49-4.06,4.46-4.06s4.47,1.23,4.47,4.06ZM36.79,29.25V25c0-2.82,2.65-4,4.78-4s4.81,1.22,4.81,4v4.22c0,2.83-2.69,4-4.81,4S36.79,32.08,36.79,29.25ZM51,48.18H45.49c-.41,0-.51-.19-.51-.51V46.2c0-.32.1-.52.51-.52H47V40.89H45.49c-.41,0-.51-.18-.51-.52V38.92c0-.34.1-.52.51-.52h.4c.34,0,1.12-.16,1.12-1.5,0-.37.22-.46.52-.46h1.74c.3,0,.5.09.52.46v8.78H51c.41,0,.52.2.52.52l0,1.47C51.56,48,51.45,48.18,51,48.18ZM64,14.91H1.43V6A4.54,4.54,0,0,1,6,1.43H59.53A4.52,4.52,0,0,1,64,6Z" />
        <path d="M27.61,38.64a1.86,1.86,0,0,0-1.74,2V44a1.85,1.85,0,0,0,3.7,0V40.39A1.86,1.86,0,0,0,27.61,38.64Z" />
        <path d="M17.1,38.62h-.27a1.8,1.8,0,0,0,.27,3.59h.13a1.8,1.8,0,1,0-.13-3.6Z" />
        <path d="M43.57,29V25.28a1.78,1.78,0,0,0-1.75-1.81h-.25a1.79,1.79,0,0,0-2,1.55,2.09,2.09,0,0,0,0,.25V29a2,2,0,0,0,4,0Z" />
        <path d="M38.59,38.66h-.16a1.81,1.81,0,0,0-1.85,1.76,1.08,1.08,0,0,0,0,.18V44a1.86,1.86,0,0,0,3.71.27,1.22,1.22,0,0,0,0-.27V40.6A1.82,1.82,0,0,0,38.59,38.66Z" />
        <path d="M42.39,6.07V6a.68.68,0,0,0-.54-.4H41.2a.33.33,0,0,0-.34.26L39.44,9.2l-.36.89-.1.23c0,.1,0,.23.14.23h.69a.72.72,0,0,0,.57-.44v-.06l.14-.41h2l.24.67c.06.2.13.25.31.25H44c.19,0,.19-.13.15-.23ZM40.94,8.73,41.52,7V7l.57,1.72Z" />
        <path d="M16.7,8.06a1.2,1.2,0,0,0,.53-1.14c0-.63-.43-1.33-1.94-1.33H13c-.2,0-.24.09-.24.25v4.44c0,.15,0,.24.24.24h2.33c1.51,0,1.94-.69,1.94-1.31A1.23,1.23,0,0,0,16.7,8.06ZM15.29,9.32H14V6.8h1.27c.43,0,.69,0,.69.39s-.21.37-.69.37H14.9c-.19,0-.25.09-.25.25v.51c0,.15.06.24.25.24h.39c.48,0,.69,0,.69.38S15.72,9.32,15.29,9.32Z" />
        <path d="M38.11,5.6h-.45a.52.52,0,0,0-.46.29L36.05,7.63,34.91,5.89a.48.48,0,0,0-.44-.29H33.7c-.19,0-.24.09-.24.24v4.47c0,.19.09.24.24.24h.56a.56.56,0,0,0,.5-.51V7.73l.84,1.48a.26.26,0,0,0,.23.18h.42c.12,0,.2-.12.24-.18l.83-1.48v2.58c0,.19.09.24.24.24h.54a.56.56,0,0,0,.5-.51V6.12A.59.59,0,0,0,38.11,5.6Z" />
        <path d="M53.92,5.61h-.54c-.15,0-.24,0-.24.25v2.6L51.38,5.9c-.11-.17-.17-.29-.38-.29h-.78c-.19,0-.24.09-.24.25v4.45c0,.19.09.24.24.24h.56a.59.59,0,0,0,.5-.51V7.65l1.77,2.62c.11.16.16.28.37.28h.76c.19,0,.24-.09.24-.24V6.12A.59.59,0,0,0,53.92,5.61Z" />
        <path d="M48.55,5.61H48c-.15,0-.25,0-.25.25v2.6L46,5.9c-.11-.17-.17-.29-.37-.29h-.79c-.19,0-.24.09-.24.25v4.45c0,.19.09.24.24.24h.56a.57.57,0,0,0,.51-.51V7.65l1.76,2.62c.11.16.17.28.38.28h.76c.19,0,.24-.09.24-.24V6.12A.59.59,0,0,0,48.55,5.61Z" />
        <path d="M20.23,5.56h-.17a2,2,0,0,0-2.21,1.77s0,.09,0,.14v1.2a2,2,0,0,0,2.08,1.92h.14a2,2,0,0,0,2.23-1.74c0-.06,0-.11,0-.17V7.48A2,2,0,0,0,20.23,5.56Zm.77,3a.85.85,0,0,1-.82.86h-.12a.83.83,0,0,1-.92-.73V7.57a.83.83,0,0,1,.79-.86h.13a.84.84,0,0,1,.94.73V8.57Z" />
        <path d="M27,5.61h-.54c-.15,0-.24,0-.24.25v2.6L24.45,5.9c-.11-.17-.17-.29-.38-.29h-.78c-.19,0-.24.09-.24.25v4.45c0,.19.09.24.24.24h.56a.59.59,0,0,0,.5-.51V7.65l1.77,2.62c.1.16.16.28.37.28h.76c.19,0,.24-.09.24-.24V6.12A.58.58,0,0,0,27,5.61Z" />
        <path d="M30.52,5.61H28.6c-.19,0-.24.09-.24.24v4.46c0,.15.05.24.24.24h1.87A2,2,0,0,0,32.7,8.81c0-.06,0-.11,0-.16V7.51A2,2,0,0,0,30.52,5.61Zm.88,2.92a.84.84,0,0,1-.8.86h-.93V6.79h.8a.82.82,0,0,1,.92.72.49.49,0,0,1,0,.12Z" />
      </svg>
    </>
  )
}

export default BadgeISO
