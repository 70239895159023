import React from "react"

const Kicker = ({ className, color, children }) => {
  return (
    <div
      className={`tracking-widest font-bold text-sm uppercase  ${
        color ? color : "text-accent-500"
      } ${className ? className : ""}`}
    >
      {children}
    </div>
  )
}

export default Kicker
