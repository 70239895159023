import React from "react"
import PropTypes from "prop-types"
import { Link } from "components"

const Tag = ({ children, className, to, colorClass }) => {
  return (
    <div
      className={`inline-block text-xs font-bold uppercase text-white px-3 py-1 rounded-sm transition transform hover:-translate-y-1 hover:bg-move tracking-wide
      ${className ? className : ""}
      ${colorClass ? colorClass : "bg-primary-500"}
      `}
    >
      <Link to={to}>{children}</Link>
    </div>
  )
}

Tag.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  to: PropTypes.string,
  colorClass: PropTypes.string,
}

export default Tag
