import React from "react"
import PropTypes from "prop-types"
import { Image } from "components"

const BackgroundImage = ({ source: image, overlay, overlayTop }) => {
  return (
    <>
      <div className="background-image">
        {overlay && (
          <div className="absolute inset-0 z-10 opacity-75 bg-gradient-r-black-90"></div>
        )}

        {overlayTop && (
          <div className="absolute inset-0 z-10 bg-gradient-t-black"></div>
        )}

        <div className="image-wrapper">
          <Image className="w-full h-full" source={image} />
        </div>
      </div>
    </>
  )
}

BackgroundImage.propTypes = {
  source: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
  overlay: PropTypes.bool,
  overlayTop: PropTypes.bool,
}

export default BackgroundImage
