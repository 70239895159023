import React, { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Link, Button } from "components"

const CookieNotice = () => {
  const [cookieNotice, setCookieNotice] = useState(false)

  const handleAccept = () => {
    setCookieNotice(false)
    localStorage.setItem("@cookie-notice-acceptance", true)
  }

  useEffect(() => {
    var cookieNoticeValue = localStorage.getItem("@cookie-notice-acceptance")

    if (!cookieNoticeValue) setCookieNotice(true)

    return () => {
      console.log("ol")
    }
  }, [])

  const duration = 0.7

  const variants = {
    initial: {
      y: "100%",
    },
    enter: {
      y: 0,
      transition: {
        duration: duration,
        delay: duration,
      },
    },
    exit: {
      y: "100%",
      transition: {
        duration: duration,
        when: "beforeChildren",
      },
    },
  }

  return (
    <AnimatePresence>
      {cookieNotice ? (
        <motion.div
          key={cookieNotice}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
          className="fixed bottom-0 left-0 right-0 bg-white z-50 py-8 shadow-soft border-t-4 border-accent-500"
        >
          <div className="container flex flex-col lg:flex-row items-center justify-between gap-8 lg:gap-16">
            <div className="flex-grow">
              Nós armazenamos dados temporariamente para melhorar sua
              experiência de navegação no nosso site. Ao usar o nosso site, você
              concorda com o uso de cookies para monitoramento e melhoria da sua
              experiência. Para mais informações, acesse nossa{" "}
              <Link
                className="text-accent-500 underline"
                to="/politica-de-privacidade"
              >
                Política de Privacidade
              </Link>
              .
            </div>

            <Button
              onClick={handleAccept}
              className="w-full lg:w-auto px-16"
              isPrimary
            >
              Ok
            </Button>
          </div>
        </motion.div>
      ) : (
        <></>
      )}
    </AnimatePresence>
  )
}

export default CookieNotice
