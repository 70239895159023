import React from "react"
import { FiHeadphones, FiClock } from "react-icons/fi"
import { AiFillLinkedin, AiFillFacebook, AiFillInstagram } from "react-icons/ai"
import { FaWhatsapp, FaEnvelope, FaYoutube } from "react-icons/fa"
import { Link } from "components"
import { useStaticQuery, graphql } from "gatsby"

const TopBar = () => {
  const { settings } = useStaticQuery(query)

  // Todo: Create editable component info from Sanity
  return (
    <div className={wrapper}>
      <div className={innerWrapper}>
        <Contact
          title="Central de Atendimento:"
          numbers={settings.customerSupport}
          openingHours="Seg. a Sex das 08h00 as 18h00"
        />
        <Icons />
      </div>
    </div>
  )
}

const query = graphql`
  {
    settings: sanitySettings {
      id
      customerSupport
    }
  }
`

const wrapper = `border-b border-gray-200`

const innerWrapper = `container py-2 flex justify-between`

const iconStyles = `transition hover:text-primary-500 transform hover:-translate-y-1`

const Icons = () => (
  <div className="space-x-4 flex items-center">
    <Link to={`https://br.linkedin.com/company/bondmann-quimica`}>
      <AiFillLinkedin className={iconStyles} />
    </Link>

    <Link to={`https://www.facebook.com/bondmann/`}>
      <AiFillFacebook className={iconStyles} />
    </Link>

    <Link to={`https://www.instagram.com/bondmannquimica/`}>
      <AiFillInstagram className={iconStyles} />
    </Link>

    <Link
      to={`https://api.whatsapp.com/send?l=pt_BR&phone=5551996438118&text=Ol%C3%A1,%20como%20vai?!`}
    >
      <FaWhatsapp className={iconStyles} />
    </Link>
    <Link to={`https://www.youtube.com/c/BondmannQu%C3%ADmica/featured`}>
      <FaYoutube className={iconStyles} />
    </Link>
    <Link to={`mailto:contato@bondmann.com.br`}>
      <FaEnvelope className={iconStyles} />
    </Link>
  </div>
)

const Contact = ({ title, numbers, openingHours }) => (
  <div className="flex space-x-2 items-center text-xs">
    <FiHeadphones />
    <span className="font-bold hidden lg:block">{title}</span>
    <span className="pr-4 space-x-3">
      {numbers.map(number => (
        <Link
          key={number}
          to={`tel:${number.replace(/(\(|\)|\s|-)/gi, "")}`}
          className="transform inline-block hover:-translate-y-1 hover:text-primary-500"
        >
          {number}
        </Link>
      ))}
    </span>
    <span className="hidden sm:flex items-center space-x-2">
      <FiClock className="ml-2" />
      <span>{openingHours}</span>
    </span>
  </div>
)

export default TopBar
