import React, { useState } from "react"
import { Button } from "components"
import { FiSearch } from "react-icons/fi"
import { navigate } from "@reach/router"

const SearchBox = () => {
  const [searchTerm, setSearchTerm] = useState("")

  const handleChange = e => {
    setSearchTerm(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (searchTerm.length <= 0) {
      return
    }

    if (typeof window !== "undefined") {
      navigate(`/busca?q=${searchTerm}`)
    }
  }

  return (
    <form
      onClick={handleSubmit}
      className="w-1/2 border-gray-300 border py-1 px-1 rounded-sm hidden lg:flex items-center "
    >
      <FiSearch className="ml-2 text-primary-500" />
      <input
        type="text"
        onChange={handleChange}
        className="outline-none w-full px-2 font-display"
        placeholder="Pesquisar..."
      />

      <Button type="submit">Pesquisar</Button>
    </form>
  )
}

export default SearchBox
