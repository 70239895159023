import React from "react"
import PropTypes from "prop-types"
import { BackgroundImage, Heading, Link, Tag } from "components"
import { motion } from "framer-motion"

const duration = 0.7

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: duration,
    },
  },
}

const ProductCard = ({
  title,
  department,
  to,
  image,
  categories,
  solutions,
  markets,
  className,
}) => {
  return (
    <motion.div
      // variants={variants}
      // initial="initial"
      // animate="enter"
      // exit="exit"
      className={`relative ${className}`}
    >
      <div className="shadow-soft relative rounded-sm border-b-4 border-accent-500 overflow-hidden">
        <Link
          className="z-20 absolute p-4 flex flex-col inset-0 transform hover:-translate-y-1 duration-700 items-start justify-end"
          to={to}
        >
          <Heading className="text-xl mb-0 text-white text-shadow" level="3">
            {title}
          </Heading>

          {categories && (
            <>
              <div className="w-full text-xs text-accent-400 tracking-wider uppercase truncate relative">
                {categories.map((category, index) => (
                  <React.Fragment key={index}>
                    {category.title}
                    {index !== categories.length - 1 ? ", " : ""}
                  </React.Fragment>
                ))}
              </div>
            </>
          )}
        </Link>

        <div className="absolute z-30 top-0 right-0 mt-4 mr-4">
          {department && (
            <Tag
              to={`/${department.slug.current}`}
              colorClass={department.slug.current}
              children={department.title}
            />
          )}
        </div>

        <div className="aspect-ratio-square">
          {image && <BackgroundImage source={image} overlayTop />}
        </div>
      </div>
    </motion.div>
  )
}

ProductCard.propTypes = {
  title: PropTypes.string,
  department: PropTypes.object,
  slug: PropTypes.string,
  image: PropTypes.object,
  categories: PropTypes.array,
}

export default ProductCard
