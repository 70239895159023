import React from "react"
import CheckBox from "./components/Checkbox"
import { Scrollbars } from "react-custom-scrollbars"

const FilterGroup = ({ title, filters, onChange, where }) => {
  return (
    <div className="mb-8 bg-white shadow-soft p-8 pr-0 border-b-4 border-accent-500 rounded-sm">
      <strong className="text-primary-500 text-xs uppercase">{title}</strong>

      <Scrollbars
        className="mt-4"
        autoHeight
        autoHeightMax={280}
        style={{ width: "100%" }}
      >
        {filters.map(filter => {
          return (
            <div key={filter.title}>
              <label htmlFor={filter.title} className="pr-4 block">
                <CheckBox
                  onChange={onChange}
                  filter={where}
                  id={filter.title}
                  value={filter.slug.current}
                />

                {filter.title}
              </label>
            </div>
          )
        })}
      </Scrollbars>
    </div>
  )
}

export default FilterGroup
