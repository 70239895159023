import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import {
  Footer,
  Header,
  Helper,
  HelloBar,
  Newsletter,
  CookieNotice,
} from "components"
import { motion, AnimatePresence } from "framer-motion"
import "src/styles/index.css"

const duration = 0.7

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: duration,
    },
  },
}

const Layout = ({ children, location }) => {
  const regex = /^\/downloads\/.*/i
  const currentPath = location.pathname
  const isLeadGenerationPage = currentPath.match(regex)

  return (
    <>
      <Helmet>
        <meta
          name="facebook-domain-verification"
          content="2bvstxb6mh27td30xxfkmsi25artv2"
        />
      </Helmet>
      {/* <Helper /> */}
      <Header title="hello" />

      <CookieNotice />

      <AnimatePresence>
        <Main
          key={location.pathname}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
          location={location}
        >
          {children}
        </Main>
      </AnimatePresence>
      {!!isLeadGenerationPage || <Newsletter />}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const Main = ({ children, ...props }) => (
  <motion.main {...props}>{children}</motion.main>
)

export default Layout
