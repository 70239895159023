import React from "react"

export default {
  types: {
    exampleCode: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}
