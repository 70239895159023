import React, { useState, useEffect } from "react"
import { Button, Heading, Input, Link } from "components"
import kebabCase from "lodash.kebabcase"
import deburr from "lodash.deburr"
import { motion } from "framer-motion"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { useLocation } from "@reach/router"

const duration = 0.35

const variants = {
  hidden: {
    opacity: 0,
    height: 0,
  },
  visible: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
}

const Form = ({
  title = "Solicite contato comercial",
  description = "Facilite a operação, manutenção e higienização de sua empresa com os produtos e serviços da Bondmann Química.",
  button = "Solicitar contato",
  quantity,
  headingLevel = "4",
  name = "formulario-padrao",
  messageField,
  lgpd = "true",
  download,
  location,
}) => {
  const [state, setState] = useState({})
  const [submitted, setSubmitted] = useState(false)
  const [checked, setChecked] = useState(true)

  const { pathname } = useLocation()

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleCheck = e => {
    setChecked(!checked)
    handleChange(e)
  }

  const simpleHash = str => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i)
      hash = (hash << 5) - hash + char
      hash &= hash // Convert to 32bit integer
    }
    return new Uint32Array([hash])[0].toString(36)
  }

  /**
   * This function handles the submission of the form, and sends the data to
   * Netlify. It also sends an event to Google Analytics, and opens a new window
   * if there is a download link.
   *
   * @param {Event} e - The submit event
   */

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        "form-location": pathname,
        ...state,
      }),
    })
      .then(() => {
        setSubmitted(false)

        // Google Tag Manager - Event
        window.dataLayer.push({
          event: "netlifyFormSubmission",
          formName: form.getAttribute("name"),
          userId: simpleHash(form.querySelector(`[id*="email"]`).value),
        })

        // console.log()

        // Google Analytics - Universal Analytics
        trackCustomEvent({
          category: "Lead",
          action: form.getAttribute("name"),
          label: pathname,
        })

        setSubmitted(true)

        if (download) {
          window.open(download, "Download")
        }
      })
      .catch(error => alert(error))
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  return (
    <div className="col-span-5 p-8 bg-white border-b-4 border-accent-500 shadow-soft">
      <Heading level={headingLevel}>{title}</Heading>
      <p>{description}</p>

      {!submitted ? (
        <form
          name={name}
          method="POST"
          netlify-honeypot="bot-field"
          data-netlify="true"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value={name} />
          <input type="hidden" name="form-location" value={pathname} />

          <div className="hidden">
            <label>
              Don’t fill this out:
              <input name="bot-field" onChange={handleChange} />
            </label>
          </div>

          <Input
            onChange={handleChange}
            required
            name={`${name}-name`}
            placeholder="Nome"
          />
          <Input
            onChange={handleChange}
            required
            name={`${name}-email`}
            placeholder="Email"
            type="email"
          />
          <Input
            onChange={handleChange}
            name={`${name}-company`}
            placeholder="Empresa"
          />
          <Input
            onChange={handleChange}
            name={`${name}-region`}
            placeholder="Estado"
          />
          <Input
            onChange={handleChange}
            name={`${name}-city`}
            placeholder="Cidade"
          />
          <Input
            onChange={handleChange}
            name={`${name}-phone`}
            placeholder="Telefone"
            type="tel"
          />
          {quantity && (
            <Input
              onChange={handleChange}
              name={`${name}-quantity`}
              placeholder="Quantidade"
              type="number"
            />
          )}

          {messageField && (
            <TextArea
              name={`${name}-message`}
              required
              onChange={handleChange}
              placeholder="Mensagem"
            />
          )}

          {lgpd && (
            <Input
              lgpd
              onChange={handleCheck}
              name={`${name}-lgpd`}
              type="checkbox"
              required
              placeholder={
                <>
                  Estou de acordo com a{" "}
                  <a
                    className="underline cursor-pointer text-accent-500"
                    onClick={() => window.open("/politica-de-privacidade")}
                  >
                    Política de Privacidade
                  </a>{" "}
                  da Bondmann, em fazer parte da Comunidade Bondmann e receber
                  contatos da empresa.
                </>
              }
              value="Concordo em receber comunicações da Bondmann Química."
            />
          )}

          <Button className="w-full" isPrimary disabled={submitted}>
            {button}
          </Button>
        </form>
      ) : (
        <></>
      )}

      <motion.div
        variants={variants}
        animate={submitted ? "visible" : "hidden"}
        className={`w-full text-white bg-accent-500 mt-8 text-sm overflow-hidden`}
      >
        <div className="relative p-8">
          {!!download
            ? "Obrigado, seu download iniciará em instantes."
            : lgpd
            ? `Obrigado por se cadastrar, você agora faz parte da Comunidade Bondmann.`
            : `Obrigado por entrar em contato com a Bondmann. Em breve alguém de nosso time retornará seu contato.`}
        </div>
      </motion.div>
    </div>
  )
}

export const TextArea = ({ name, placeholder, onChange, required }) => (
  <label htmlFor={name}>
    <span className="hidden">{placeholder}</span>
    <textarea
      id={name}
      rows="4"
      name={name}
      required={required}
      onChange={onChange}
      className="flex items-center w-full px-4 py-2 mb-6 placeholder-gray-500 border border-gray-300 rounded-sm outline-none focus:text-primary-500 focus:border-primary-500"
      placeholder={placeholder}
    />
  </label>
)

export default Form
