import React from "react"
import PropTypes from "prop-types"
import { Button, Link, Logo } from "components"
import { Navigation, TopBar, SearchBox } from "./components"

const Header = ({ title }) => {
  return (
    <header className="relative z-40 bg-white shadow-lg">
      <TopBar />

      <div className="container flex items-center justify-between w-full py-8">
        <div className="w-48">
          <Link className="flex justify-start w-48 text-left" to="/">
            <Logo />
          </Link>
        </div>

        <SearchBox />

        <Button isPrimary to="/contato">
          Orçamentos
        </Button>
      </div>

      <Navigation />
    </header>
  )
}

Header.propTypes = {
  title: PropTypes.string,
}

export default Header
