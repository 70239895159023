import React from "react"
import PropTypes from "prop-types"
import { BackgroundImage, Heading, Tag, Kicker } from "components"
import { motion } from "framer-motion"

const duration = 0.7

const container = {
  visible: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.2,
      delayChildren: duration,
    },
  },
}
const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const Hero = ({
  title,
  image,
  overlay,
  description,
  tag,
  kicker,
  backgroundColor,
  extraPadding,
}) => {
  return (
    <>
      <motion.section
        variants={container}
        initial="hidden"
        animate="visible"
        className={`${
          extraPadding ? "py-40" : "py-24"
        } bg-2x relative overflow-hidden ${
          backgroundColor ? backgroundColor : "bg-gradient-tl-blue"
        }`}
      >
        <div className="relative container z-40  grid lg:grid-cols-12 gap-16">
          <div className="space-y-4 lg:col-span-5">
            {tag && (
              <motion.div
                className="content"
                variants={item}
                transition="easeInOut"
              >
                <Tag children={tag} colorClass="bg-gradient-tl-green bg-2x" />
              </motion.div>
            )}

            {kicker && (
              <motion.div
                className="content"
                variants={item}
                transition="easeInOut"
              >
                <Kicker children={kicker} />
              </motion.div>
            )}

            <motion.div
              className="text-shadow"
              variants={item}
              transition="easeInOut"
            >
              <Heading className="text-white mb-0 text-shadow" level="1">
                {title}
              </Heading>
            </motion.div>

            {description && (
              <motion.div
                variants={item}
                transition="easeInOut"
                className="text-white text-shadow"
              >
                {description}
              </motion.div>
            )}
          </div>
        </div>

        {/* <Image source={image} /> */}
        {image && <BackgroundImage overlay={overlay && true} source={image} />}
      </motion.section>
    </>
  )
}

Hero.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  image: PropTypes.object,
  data: PropTypes.object,
  overlay: PropTypes.bool,
}

export default Hero
