import React from "react"
import { Heading, BackgroundImage, Section, Form } from "components"
import { useStaticQuery, graphql } from "gatsby"

const Newsletter = () => {
  const { comunidade, banner } = useStaticQuery(staticQuery)
  return (
    <div>
      <Section container bgImage={comunidade}>
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          <div className="text-white">
            <Heading className="text-accent-500">Acompanhe a Bondmann</Heading>

            <p className="text-xl">
              Fique por dentro de lançamentos, novidades e conteúdos técnicos
              que podem trazer mais competitividade ao seu negócio.
            </p>

            <p>
              Cadastre seus dados e faça parte de uma comunidade que busca
              continuamente a facilitação e qualificação de seus processos de
              operação, manutenção e higienização.
            </p>
          </div>

          <div>
            <Form
              name="comunidade-bondmann"
              button="Cadastrar na Comunidade"
              title="Comunidade Bondmann"
              description="Preencha o formulário com os seus dados para fazer parte da Comunidade Bondmann."
              lgpd
            />
          </div>
        </div>
      </Section>
    </div>
  )
}

const staticQuery = graphql`
  query {
    comunidade: file(relativePath: { eq: "comunidade-bondmann.png" }) {
      asset: childImageSharp {
        fluid(maxWidth: 1600, quality: 96) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Newsletter
