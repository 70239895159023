import React from "react"
import PropTypes from "prop-types"
import { Link } from "components"
import { AiFillLinkedin, AiFillFacebook } from "react-icons/ai"
import { FaWhatsapp, FaEnvelope, FaYoutube } from "react-icons/fa"

// Todo: Manage social links on Sanity CMS.

const SocialMediaIcons = ({ socialLinks }) => {
  return (
    <div className="space-x-4 flex items-center">
      <Link to={`https://br.linkedin.com/company/bondmann-quimica`}>
        <AiFillLinkedin className="icon" />
      </Link>
      <Link to={`https://www.facebook.com/bondmann/`}>
        <AiFillFacebook className="icon" />
      </Link>
      <Link
        to={`https://api.whatsapp.com/send?phone=5551996438118&text=Ol%C3%A1,%20como%20vai?!`}
      >
        <FaWhatsapp className="icon" />
      </Link>
      <Link to={`https://www.youtube.com/c/BondmannQu%C3%ADmica/featured`}>
        <FaYoutube className="icon" />
      </Link>
      <Link to={`mailto:contato@bondmann.com.br`}>
        <FaEnvelope className="icon" />
      </Link>
    </div>
  )
}

SocialMediaIcons.propTypes = {
  socialLinks: PropTypes.array,
}

export default SocialMediaIcons
