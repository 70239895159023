import React from "react"
import { Section, Heading, ProductCard } from "components"
import sampleSize from "lodash.samplesize"

const RelatedProducts = ({
  title = "Produtos relacionados",
  background,
  limit,
  data,
}) => {
  return (
    <>
      <Section background={background}>
        <Heading level="2">{title}</Heading>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {sampleSize(data, limit).map(
            ({ node: { title, slug, department, image } }) => {
              return (
                <ProductCard
                  key={slug.current}
                  title={title}
                  to={`/produtos/${slug.current}`}
                  department={department}
                  image={image}
                />
              )
            }
          )}
        </div>
      </Section>
    </>
  )
}

export default RelatedProducts
