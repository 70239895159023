import React from "react"
import PropTypes from "prop-types"
import { BackgroundImage } from "components"
import { motion } from "framer-motion"

const duration = 0.7

const container = {
  visible: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.2,
      delayChildren: duration,
    },
  },
}

const Section = ({
  children,
  fullWidth,
  background,
  bgImage,
  className,
  overlay,
}) => {
  const wrapperStyle = `
    ${fullWidth ? "" : "container"}
    py-16 z-20 relative
  `

  return (
    <>
      <motion.section
        variants={container}
        initial="hidden"
        animate="visible"
        className={`relative ${background && background}`}
      >
        <div className={`${wrapperStyle} ${className}`}>{children}</div>

        {bgImage && <BackgroundImage source={bgImage} overlay={overlay} />}
      </motion.section>
    </>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  container: PropTypes.bool,
  overlay: PropTypes.bool,
}

export default Section
